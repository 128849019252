.user-profile-container {
  position: absolute;
  width: 170px;
  height: 200px;
  background-color: #2a2a2a;
  bottom: 15px;
  left: 15px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 14px;

  .sign-out-button {
    width: 80%;
    height: 40px;
    background-color: #44444f;
    color: #c9c9dd;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 5px;
    margin-top: 15px;
    font-weight: 500;
    font-size: 14px;
    transition: 0.2s;
    &:hover {
      background-color: #c9c9dd;
      color: #44444f;
    }
  }

  .user-email {
    color: #adadb3;
    font-size: 13px;
    margin-top: 10px;
    text-align: center;
    text-wrap: nowrap;
  }

  a {
    color: #adadb3;
    font-size: 13px;
    margin-top: 10px;
  }
}