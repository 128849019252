body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

// Reset link styles
a {
  color: inherit; // Links use the text color of their parent
  text-decoration: none; // Remove underline or any decoration
  background-color: transparent; // No background color
  -webkit-text-decoration-skip: objects; // Skip links when underlining text in Safari

  &:hover, &:focus {
    color: inherit; // Maintain text color on hover/focus
    text-decoration: underline; // Optional: underline on hover/focus for clarity
  }

  &:active {
    outline: 0; // Remove outline on active links
  }

  // Accessibility: outline on focus for keyboard navigation
  &:focus {
    outline: thin dotted;
  }

  // Remove the outline on IE10+ for click or touch
  &:focus:not(:focus-visible) {
    outline: none;
  }
}

