.navigation-component {
  position: fixed;
  width: 200px;
  height: 100%;
  background-color: #575767;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  h1 {
    position: relative;
    color: #fff;
    top: 15px;
    left: 0px;
    width: 100%;
    font-size: 22px;
    font-family: 'Silkscreen', monospace;
    margin: 0;
    padding: 0;
    text-align: center;
    margin-bottom: 80px;
    @media only screen and (max-width: 800px) {
      font-size: 18px;
    }
  }

  .menu-item {
    position: relative;
    text-align: center;
    font-weight: 500;
    cursor: pointer;
    padding: 7px 15px;
    background-color: #44444f;
    color: #c9c9dd;
    border-radius: 5px;
    bottom: 10px;
    width: 70%;
    font-size: 14px;
    transition: 0.2s;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 10px;
    @media only screen and (max-width: 1000px) {
      max-width: 200px;
      left: 0px;
    }
  }

  .menu-item:hover, .menu-item-active {
    background-color: #c9c9dd;
    color: #44444f;
  }

  .profile-icon-container {
    position: absolute;
    bottom: 10px;
    left: 0px;
  }

}