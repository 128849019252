.header-container {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 60px;
  background-color: #eee;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}